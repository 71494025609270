import { PageHelp } from "@/components/styled/pageHelp";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { remove_timestamp } from "@/lib/utils";
import {
  add_new_review,
  delete_file_review,
  list_reviews,
} from "api/review_space";
import { ErrorPage, LoadingPage, LoadingSpinner, Title } from "components";
import { useRef } from "react";
import { useDropzone } from "react-dropzone";

import { MdDelete } from "react-icons/md";
import { Link, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import UploadFromDrive from "./UploadFromDrive";
import UploadFromLocal from "./UploadFromLocal";
import { get_settings } from "api/settings";

const StatusPill = (status) => {
  if (status === "Not Started") {
    return (
      <div className="text-white font-semibold bg-gray-600 px-2 py-1 rounded-full text-center w-fit">
        Not Started
      </div>
    );
  } else if (status === "In Progress") {
    return (
      <div className="text-white font-semibold bg-yellow-600 px-2 py-1 rounded-full text-center w-fit">
        In Progress
      </div>
    );
  } else if (status === "Failed") {
    return (
      <div className="text-white font-semibold bg-red-600 px-2 py-1 rounded-full text-center w-fit">
        Failed
      </div>
    );
  } else if (status === "Processed") {
    return (
      <div className="text-white font-semibold bg-green-600 px-2 py-1 rounded-full text-center w-fit">
        Done
      </div>
    );
  }
};

export default function ListReviews() {
  const { projectId } = useParams();

  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  const {
    data: reviews,
    isLoading: isReviewsLoading,
    error: reviewsError,
  } = useSWR(`list_reviews/${projectId}`, () =>
    list_reviews({ project_id: projectId })
  );

  const {
    isLoading: isDeletingReview,
    error: deleteReviewError,
    trigger: deleteReview,
  } = useSWRMutation(
    `delete_review`,
    (key, { arg }) =>
      delete_file_review({ project_id: projectId, file_id: arg }),
    {
      onSuccess: () => {
        mutate(`list_reviews/${projectId}`);
      },
    }
  );

  if (isReviewsLoading || isGettingSettings) return <LoadingPage />;
  if (reviewsError || settingsError) return <ErrorPage />;

  return (
    <div className="bg-white grow h-full">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Proposal Reviews" />
        <PageHelp
          title="Review Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                Red Team Reviews provide quick feedback on your proposals. In
                just a few minutes, you'll receive a summary of key takeaways,
                along with an overview of strengths, areas for improvement, and
                specific feedback from Reviewers 1 and 3 (because we all know
                Reviewer 2 never actually read the paper!). We currently accept
                only PDF and DOCX files under 10MB.
              </div>
            </div>
          }
        />
      </div>
      <div className="text-base">
        Proposal Reviews provides an efficient way to review your key proposal
        documents without the need to ask and wait for colleague feedback.
        Simply upload your proposal in PDF or DOCX format, and within minutes,
        you'll receive a comprehensive review, including a general feedback
        outline, a summary, and input from two virtual reviewers.
      </div>
      <div className="flex justify-end">
        <UploadFromLocal
          projectId={projectId}
          user_tier={settings?.user_tier}
        />
        {/* <UploadFromDrive
          projectId={projectId}
          prompt_upgrade={settings?.prompt_upgrade}
        /> */}
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>File Name</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Created</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {reviews.map((review) => (
            <TableRow className="group" key={review.id}>
              <TableCell>
                {review.status === "Processed" ? (
                  <Link
                    className="hover:text-fuchsia"
                    to={`/project/${projectId}/review-space/${review.id}`}
                  >
                    {review.name}
                  </Link>
                ) : (
                  <span>{review.name}</span>
                )}
              </TableCell>
              <TableCell>{StatusPill(review.status)}</TableCell>
              <TableCell>{remove_timestamp(review.creation_time)}</TableCell>
              <TableCell>
                <Dialog
                  className="hover:text-fuchsia invisible group-hover:visible"
                  trigger={
                    <span>
                      <MdDelete className="w-4 h-4" />
                    </span>
                  }
                  title={<div>Are you sure you want to delete this file?</div>}
                  content={
                    <div>
                      <div>The file will be permanently deleted.</div>
                      <div className="flex justify-between mt-8">
                        <DialogClose asChild>
                          <button className="text-zinc-600 hover:underline">
                            Cancel
                          </button>
                        </DialogClose>
                        <button
                          className="text-red-600 hover:underline"
                          disabled={isDeletingReview}
                          onClick={() => deleteReview(review.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  }
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
