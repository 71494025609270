import { ErrorPage, LoadingPage, Title, LoadingSpinner } from "components";
import TopicIdea from "./TopicIdea";
import { get_brainstorm, get_project, get_profile, get_general } from "api";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { mutate } from "swr";
import { add_brainstorm_ideas, generate_brainstorm_ideas } from "api";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { PageHelp } from "@/components/styled/pageHelp";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { ScrollArea } from "@/components/ui/scroll-area";
import ManualAddIdea from "./ManualAddIdea";
import GenerateBrainstormIdeas from "./GenerateBrainstormIdeas";
import { get_settings } from "api/settings";

export default function Brainstorming() {
  const { projectId } = useParams();

  const {
    data: brainstormIdeas,
    isLoading: isGettingBrainstormIdeas,
    error: brainstormIdeasError,
  } = useSWR(`/project/idea/brainstorm/${projectId}`, () =>
    get_brainstorm({ project_id: projectId })
  );

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: researchIdea,
    isLoading: isResearchIdeaLoading,
    error: researchIdeaError,
  } = useSWR(`/project/idea/general/${projectId}`, () =>
    get_general({ project_id: projectId, tile_id: 4 })
  );

  const { data: settings } = useSWR(`get_settings`, () => get_settings());

  if (isGettingBrainstormIdeas || isProjectLoading || isResearchIdeaLoading)
    return <LoadingPage />;
  if (brainstormIdeasError || isProjectError || researchIdeaError)
    return <ErrorPage />;

  const hasEditorAccess =
    project?.users.find((p) => p.user_id === settings.user_id).relationship !==
    "viewer";
  const ideas =
    brainstormIdeas?.items
      .filter((i) => i.brainstorm_id)
      .toSorted((a, b) => a.brainstorm_id.localeCompare(b.brainstorm_id)) || [];

  const isResearchIdeaFilledOut =
    researchIdea.idea?.length && researchIdea.description?.length;

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Brainstorm Ideas" />
        <PageHelp
          title="Ideation Space"
          content={
            <div className="flex flex-col gap-y-4">
              <div>
                The Ideation Space is designed to help you refine your research
                idea.
              </div>
              <div>
                <h2 className="font-semibold">Research Idea</h2>
                Here you will tell us a more about what idea you would like to
                get funded.
              </div>
              <div>
                <h2 className="font-semibold">Background Material</h2>
                Enhance our understanding of your idea by searching for and
                linking relevant papers. You can search for papers using Arxiv
                or Semantic Scholar. Note: adding papers here will help us to
                recommend better calls for you in the future.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Ideas</h2>
                Use this section to further develop your idea, take notes, and
                explore new perspectives. You can manually add ideas to the
                brainstorming list, or let us generate suggestions for you.
                Ideas are generated based on your Research Idea, Description,
                and any Background Materials you've provided.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Sessions</h2>
                With Sessions, you can easily create brainstorming rooms with
                experts from various backgrounds. Each session lasts a few days,
                and after it ends, we'll provide a summary of the discussion.
                You can start new sessions based on previous topics or begin
                with a fresh idea.
              </div>
            </div>
          }
        />
      </div>
      <div className="text-base">
        Brainstorming Ideas is a dedicated space for users to organize, develop,
        and refine their thoughts. You can add an unlimited number of ideas,
        making it easy to capture all your creative insights. If you ever need
        inspiration or wish to explore new directions, our platform can assist
        by generating relevant ideas tailored to your current focus.
      </div>
      <ScrollArea className="grow shrink  px-4">
        {ideas?.length > 0 ? (
          <div className="flex flex-col">
            {ideas.map((item, i) => (
              <TopicIdea
                hasEditorAccess={hasEditorAccess}
                key={item.brainstorm_id}
                projectId={projectId}
                index={i + 1}
                {...item}
              />
            ))}
          </div>
        ) : (
          <div className="border-dashed border-4 rounded-md flex flex-col justify-center items-center grow h-full">
            <div className="font-semibold">
              You haven't added any brainstorming ideas yet.
            </div>
          </div>
        )}
      </ScrollArea>
      {hasEditorAccess && (
        <>
          <div className="flex gap-x-4 mt-8">
            <GenerateBrainstormIdeas
              projectId={projectId}
              isResearchIdeaFilledOut={isResearchIdeaFilledOut}
            />

            <ManualAddIdea projectId={projectId} />
          </div>
        </>
      )}
    </div>
  );
}
