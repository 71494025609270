import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { delete_session, update_session_title } from "api";
import { LoadingSpinner } from "components";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function EditBrainstormSessionInfo({
  projectId,
  sessionId,
  title,
  trigger,
}) {
  const navigate = useNavigate();
  const [sessionTitle, setSessionTitle] = useState(title);

  const {
    isMutating: isUpdatingSessionTitle,
    error: updateSessionTitleError,
    trigger: updateSessionTitle,
  } = useSWRMutation(
    `update_session_title`,
    () =>
      update_session_title({
        session_id: sessionId,
        name: sessionTitle,
      }),
    {
      onSuccess: () => {
        mutate(`get_brainstorm_sessions/${projectId}`);
        mutate(
          `get_brainstorm_session_info/${sessionId}`,
          (d) => ({ ...d, title: sessionTitle }),
          { revalidate: false }
        );
      },
    }
  );

  const {
    isMutating: isDeletingSession,
    error: deleteSessionError,
    trigger: deleteSession,
  } = useSWRMutation(
    `delete_session`,
    () =>
      delete_session({
        session_id: sessionId,
      }),
    {
      onSuccess: () => {
        mutate(`get_brainstorm_sessions/${projectId}`, undefined, {
          revalidate: true,
        });
        navigate(`/project/${projectId}/ideation-space/brainstorm-session`);
      },
    }
  );

  const handleUpdateSessionTitle = () => {
    if (sessionTitle === title) return;
    updateSessionTitle();
  };

  const handleDeleteSession = () => {
    deleteSession();
  };

  return (
    <Dialog>
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent>
        <div className="flex flex-col gap-y-8">
          <div>
            <div className="font-semibold">Session Title</div>
            <form
              className="flex gap-x-2"
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateSessionTitle();
              }}
            >
              <Input
                onChange={(e) => setSessionTitle(e.target.value)}
                value={sessionTitle}
              />
              <button
                className="btn-fuchsia"
                disabled={isUpdatingSessionTitle || sessionTitle === title}
              >
                {isUpdatingSessionTitle ? <LoadingSpinner /> : "Update"}
              </button>
            </form>
          </div>
          <div>
            <button
              className="flex gap-x-1 items-center text-red-500 hover:underline"
              onClick={handleDeleteSession}
            >
              <MdDelete /> Delete Session
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
