import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { remove_timestamp } from "@/lib/utils";
import {
  bookmark_project_call,
  unbookmark_project_call,
  search_similar_calls,
} from "api";
import { LoadingSpinner } from "components";
import { BookmarkIcon, ExternalLinkIcon } from "lucide-react";
import { FaExternalLinkAlt, FaRegBookmark } from "react-icons/fa";

import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function SuggestedCall({
  agency,
  bookmarked,
  call_id,
  close_date,
  description,
  posted_date,
  title,
  url,
  projectId,
  searchSimilarCalls,
  setCalls,
}) {
  const {
    isMutating: isBookmarking,
    error: bookmarkError,
    trigger: bookmarkCall,
  } = useSWRMutation(
    `bookmark_project_call`,
    () =>
      bookmark_project_call({
        call_id: call_id,
        project_id: parseInt(projectId),
      }),
    {
      onSuccess: () => {
        setCalls((c) => ({
          ...c,
          calls: c.calls.map((call) =>
            call.call_id === call_id ? { ...call, bookmarked: true } : call
          ),
        }));
        mutate(`get_project_calls/${projectId}`, undefined, {
          revalidate: true,
        });
      },
    }
  );

  const {
    isMutating: isUnbookmarking,
    error: unbookmarkError,
    trigger: unbookmarkCall,
  } = useSWRMutation(
    `unbookmark_project_call`,
    () =>
      unbookmark_project_call({
        call_id: call_id,
        project_id: parseInt(projectId),
      }),
    {
      onSuccess: () => {
        setCalls((c) => ({
          ...c,
          calls: c.calls.map((call) =>
            call.call_id === call_id ? { ...call, bookmarked: false } : call
          ),
        }));
        mutate(`get_project_bookmarked_calls/${projectId}`, undefined, {
          revalidate: true,
        });
      },
    }
  );

  const newCloseDate = remove_timestamp(close_date);
  const newPostedDate = remove_timestamp(posted_date);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="flex flex-col border-[1px] rounded-lg p-4 gap-y-2 hover:border-fuchsia text-left hover:cursor-pointer">
          <div>
            <span className="text-2xl font-semibold">{title}</span>
          </div>
          <div>{agency}</div>
          <div className="text-base">Open: {newPostedDate}</div>
          <div className="text-base">Closes: {newCloseDate}</div>
          <div className="break-words text-base">
            {description.substring(0, 300) + "..."}
          </div>
          <div className="flex justify-end">
            <div>
              <button
                className="btn-fuchsia"
                onClick={(e) => {
                  e.stopPropagation();
                  searchSimilarCalls(call_id);
                }}
              >
                Search Similar Calls
              </button>
            </div>
          </div>
          {bookmarkError && (
            <span className="text-red-500">
              There was an issue bookmarking this call. Please try again
            </span>
          )}
          {unbookmarkError && (
            <span className="text-red-500">
              There was an issue unbookmarking this call. Please try again
            </span>
          )}
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-4xl max-h-screen">
        <div className="flex gap-x-4">
          {bookmarked ? (
            <div>
              <button
                className="btn-secondary"
                disabled={isUnbookmarking}
                onClick={unbookmarkCall}
              >
                Unbookmark{" "}
                {isUnbookmarking ? (
                  <LoadingSpinner className="h-full" />
                ) : (
                  <BookmarkIcon className="h-4 w-4" />
                )}
              </button>
            </div>
          ) : (
            <div>
              <button
                className="btn-secondary"
                disabled={isBookmarking}
                onClick={bookmarkCall}
              >
                Bookmark
                {isBookmarking ? (
                  <LoadingSpinner className="h-full" />
                ) : (
                  <BookmarkIcon className="h-4 w-4" />
                )}
              </button>
            </div>
          )}
          {url && (
            <a
              className="btn-secondary hover:opacity-60"
              href={url}
              target="_blank"
            >
              Open Link <ExternalLinkIcon className="w-4 h-4" />
            </a>
          )}
        </div>
        <div className="text-black flex flex-col gap-y-2">
          <DialogHeader>
            <DialogTitle className="text-2xl font-semibold">
              {title}
            </DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>

          <div>{agency}</div>
          <div>Open: {newPostedDate}</div>
          <div>Closes: {newCloseDate}</div>
          <div className="break-words text-base">{description}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
