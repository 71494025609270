import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { update_project_name } from "api";
import { Title } from "components";
import { useState } from "react";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

export default function EditProjectTitle({ projectId, title }) {
  const [tempTitle, setTempTitle] = useState(title);

  const { trigger: renameProject } = useSWRMutation(
    `update_project_name/${projectId}`,
    () => update_project_name({ project_id: projectId, name: tempTitle }),
    {
      onSuccess: () => {
        mutate(
          `get_project/${projectId}`,
          (data) => {
            return { ...data, name: tempTitle };
          },
          { revalidate: false }
        );
        mutate(
          (key) => key?.startsWith("list_projects"),
          (data) =>
            data?.map((p) =>
              p.project_id === parseInt(projectId)
                ? { ...p, project_name: tempTitle }
                : p
            ),
          { revalidate: false }
        );
      },
    }
  );

  return (
    <Dialog>
      <DialogTrigger>
        <Title className="text-4xl hover:underline" value={title} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit Project Title</DialogTitle>
          <DialogDescription>Update your project title</DialogDescription>
        </DialogHeader>
        <Input
          value={tempTitle}
          onChange={(e) => setTempTitle(e.target.value)}
        />
        <div className="flex justify-end">
          <button
            className="btn-fuchsia"
            disabled={title === tempTitle}
            onClick={renameProject}
          >
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
