import { Title } from "components";
import Markdown from "react-markdown";
export default function FileSummary({ review_outline, summary }) {
  console.log(review_outline);
  return (
    <div className="flex flex-col overflow-y-scroll mb-8 gap-y-12">
      <div>
        <Title className="text-2xl" value="Overview" />
        <div className="text-base">
          <Markdown
            className="markdown-styles"
            // renderers={{"p": 'span'}}
            components={{"p":"span"}}
            // unwrapDisallowed
          >
            {review_outline}
          </Markdown>
        </div>
      </div>
      <div>
        <Title className="text-2xl" value="Summary" />
        <div className="text-base">{summary}</div>
      </div>
    </div>
  );
}
