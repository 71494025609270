import { BlocksIcon, InfinityIcon, WorkflowIcon } from "lucide-react";
import Feature from "./Feature";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { refresh_token } from "api";
import useSWRMutation from "swr/mutation";
import { useAuthStore } from "stores/useAuthStore";
import { useEffect } from "react";

export default function Success({}) {
  const { refreshToken } = useAuthStore();
  const { trigger: callRefreshToken } = useSWRMutation("refresh_token", () =>
    refresh_token({ refreshToken: refreshToken })
  );

  useEffect(() => {
    callRefreshToken();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="max-w-3xl w-full flex flex-col gap-4">
        <div className="text-4xl text-center">
          Welcome to Initium.AI! <br /> We're so happy to have you here!
        </div>

        <div> Here's some of the new things you can do!</div>
        <div className="grid grid-cols-2 gap-2">
          <Feature
            icon={<InfinityIcon />}
            title="Unlimited Credits"
            description="Use our features as much as you want!"
          />
          <Feature
            icon={<BlocksIcon />}
            title="New Project Features"
            description="Find new features to help with your grant writing process."
            link={
              <Link to="/" className="btn-secondary w-fit">
                Go to Projects
              </Link>
            }
          />
          <Feature
            icon={<WorkflowIcon />}
            title="Integrations"
            description={<div>Add integrations to ease your workflow.</div>}
            link={
              <Link to="/settings/integrations" className="btn-secondary w-fit">
                Go to Integrations
              </Link>
            }
          />
        </div>
      </div>
    </div>
  );
}
