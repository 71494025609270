import { FaExternalLinkAlt, FaRegBookmark } from "react-icons/fa";
import { bookmark_project_call, unbookmark_project_call } from "api";
import useSWRMutation from "swr/mutation";
import { LoadingSpinner } from "components";
import { mutate } from "swr";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { remove_timestamp } from "@/lib/utils";
import { BookmarkIcon, ExternalLink, ExternalLinkIcon } from "lucide-react";

export default function Call({
  title,
  agency,
  description,
  close_date,
  bookmarked,
  call_id,
  url,
  posted_date,
  projectId,
}) {
  const {
    isMutating: isBookmarking,
    error: bookmarkError,
    trigger: bookmark,
  } = useSWRMutation(
    `bookmark_call/${projectId}/${call_id}`,
    async () =>
      bookmark_project_call({ project_id: projectId, call_id: call_id }),
    {
      onSuccess: () => {
        mutate(`get_project_bookmarked_calls/${projectId}`, undefined, {
          revalidate: true,
        });
        mutate(
          `get_recommended_calls/${projectId}`,
          (d) => ({
            ...d,
            calls: d.calls.map((call) =>
              call.call_id === call_id ? { ...call, bookmarked: true } : call
            ),
          }),
          {
            revalidate: false,
          }
        );
      },
    }
  );
  const {
    isMutating: isUnbookmarking,
    error: unbookmarkError,
    trigger: unbookmarkCall,
  } = useSWRMutation(
    `unbookmark_project_call`,
    () =>
      unbookmark_project_call({
        call_id: call_id,
        project_id: parseInt(projectId),
      }),
    {
      onSuccess: () => {
        mutate(
          `get_recommended_calls/${projectId}`,
          (d) => ({
            calls: d.calls.map((call) =>
              call.call_id === call_id ? { ...call, bookmarked: false } : call
            ),
          }),
          {
            revalidate: false,
          }
        );
        mutate(`get_project_bookmarked_calls/${projectId}`, undefined, {
          revalidate: true,
        });
      },
    }
  );

  const newCloseDate = remove_timestamp(close_date);
  const newPostedDate = remove_timestamp(posted_date);

  return (
    <Dialog>
      <DialogTrigger>
        <div className="flex flex-row border-[1px] rounded-lg p-4 gap-x-4 hover:border-fuchsia text-left">
          <div className="flex flex-col">
            <div>
              <span className="text-2xl underline font-semibold">{title}</span>
            </div>

            <div>{agency}</div>
            <div className="text-base">Posted: {newPostedDate}</div>
            <div className="text-base">Closes: {newCloseDate}</div>

            <div className="break-words rounded-md text-base">
              {description.substring(0, 300) + "..."}
            </div>

            {bookmarkError && (
              <span className="text-red-500">
                There was an issue bookmarking this call. Please try again.
              </span>
            )}
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-4xl max-h-screen overflow-y-scroll">
        <div className="flex gap-x-4">
          {bookmarked ? (
            <button
              className="btn-secondary"
              onClick={unbookmarkCall}
              disabled={isUnbookmarking}
            >
              Unbookmark
              {isUnbookmarking ? (
                <LoadingSpinner />
              ) : (
                <BookmarkIcon className="w-4 h-4" />
              )}
            </button>
          ) : (
            <button
              className="btn-secondary"
              onClick={bookmark}
              disabled={isBookmarking}
            >
              Bookmark{" "}
              {isBookmarking ? (
                <LoadingSpinner />
              ) : (
                <BookmarkIcon className="w-4 h-4" />
              )}
            </button>
          )}

          {/* <button className="btn-secondary">Select for Project</button> */}
          {url && (
            <a
              className="btn-secondary hover:opacity-80"
              href={url}
              target="_blank"
            >
              Open Link <ExternalLink className="w-4 h-4" />
            </a>
          )}
        </div>
        <DialogHeader>
          <DialogTitle>
            <span className="text-2xl underline font-semibold">{title}</span>
          </DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <div className="text-black flex flex-col gap-y-2">
          <div>{agency}</div>
          <div>
            <span className="font-semibold">Posted:</span> {newPostedDate}
          </div>
          <div>
            <span className="font-semibold">Closes:</span> {newCloseDate}
          </div>
          <div>{description}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
