import Markdown from "react-markdown";
import { MdFace, MdFace4 } from "react-icons/md";

export default function InitiumChatMessage({
  sender,
  message,
  creator_name,
  background,
}) {
  const isSender1 = sender === "Researcher 1";

  return (
    <div className="w-2/3 flex gap-x-2 items-end">
      <div
        className={`rounded-full bg-pastel-blue border-2 h-fit w-fit translate-y-1/2`}
      >
        {isSender1 ? (
          <MdFace4 className="w-8 h-8" />
        ) : (
          <MdFace className="w-8 h-8" />
        )}
      </div>
      <div className="bg-pastel-blue flex flex-col px-4 py-1 rounded-2xl rounded-es-none text-sm">
        <div
          className={`border-b-1 border-offblack font-semibold text-base text-blue`}
        >
          <div>{sender}</div>
          <div className="capitalize text-xs">&#x28;{background}&#x29;</div>
        </div>
        <div>
          <Markdown>{message}</Markdown>
        </div>
      </div>
    </div>
  );
}
