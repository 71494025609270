import { ErrorPage, LoadingPage, Title } from "components";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { get_user_bookmarked_calls } from "api";
import useSWR from "swr";
import Call from "./Call";
import { useEffect, useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Link } from "react-router-dom";

export default function UserBookmarkedCalls({}) {
  const [projects, setProjects] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState("all");
  const {
    data: bookmarkedCalls,
    isLoading: isBookmarkedCallsLoading,
    error: bookmarkedCallsError,
  } = useSWR(`get_user_bookmarked_calls`, () => get_user_bookmarked_calls(), {
    onSuccess: (data) => {
      setProjects([
        ...new Map(
          data?.map((item) =>
            item.project_id === null
              ? [
                  "user",
                  { project_id: "user", project_name: "No Attached Project" },
                ]
              : [
                  item.project_id,
                  {
                    project_id: item.project_id,
                    project_name: item.project_name,
                  },
                ]
          )
        ).values(),
      ]);
    },
  });

  useEffect(() => {
    const filterCallsByProject = () => {
      if (selectedProjectId === "all") return bookmarkedCalls;
      else if (selectedProjectId === "user")
        return bookmarkedCalls.filter((call) => call.project_id === null);
      else
        return bookmarkedCalls.filter(
          (call) => call.project_id === selectedProjectId
        );
    };
    setFilteredCalls(filterCallsByProject);
  }, [selectedProjectId, bookmarkedCalls]);

  if (isBookmarkedCallsLoading) return <LoadingPage />;
  if (bookmarkedCallsError) return <ErrorPage />;

  return (
    <div className="flex flex-col w-full gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Bookmarked Calls" />
      </div>

      {bookmarkedCalls?.length > 0 ? (
        <>
          <div>
            <Select
              value={selectedProjectId}
              onValueChange={(v) => setSelectedProjectId(v)}
            >
              <SelectTrigger>
                <SelectValue placeholder="All Projects" />
              </SelectTrigger>
              <SelectContent>
                {[
                  { project_id: "all", project_name: "All Projects" },
                  ...projects,
                ].map(({ project_id, project_name }) => (
                  <SelectItem value={project_id} key={project_id}>
                    {project_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <ScrollArea className="p-4">
            <div className="flex flex-col gap-y-8">
              {filteredCalls?.map((call) => (
                <Call key={call.call_id} {...call} />
              ))}
            </div>
          </ScrollArea>
        </>
      ) : (
        <div className="">
          You don't have any bookmarked calls yet. To bookmark calls, create a{" "}
          <Link className="text-fuchsia hover:underline" to={"/"}>
            project
          </Link>{" "}
          and search for calls relevant to your idea.
        </div>
      )}
    </div>
  );
}
