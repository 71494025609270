import { ErrorPage, LoadingPage, Title } from "components";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { get_project, get_profile, get_project_calls } from "api";
import Call from "./Call";
import { PageHelp } from "@/components/styled/pageHelp";
import { ScrollArea } from "@/components/ui/scroll-area";
import { get_project_bookmarked_calls } from "api/grant_space";

export default function BookmarkedCalls() {
  const { projectId } = useParams();

  const {
    data: calls,
    isLoading: isCallsLoading,
    error: isCallsError,
  } = useSWR(`get_project_bookmarked_calls/${projectId}`, () =>
    get_project_bookmarked_calls({ project_id: projectId })
  );

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  if (isCallsLoading || isProjectLoading || isProfileLoading)
    return <LoadingPage />;
  if (isCallsError || isProjectError || isProfileError) return <ErrorPage />;

  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";

  return (
    <div className="flex flex-col h-full gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Bookmarks" />
        <PageHelp
          title="Grant Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Grant Space is dedicated to discovering and managing funding
                opportunities.
              </div>
              <div>
                <h2 className="font-semibold">Recommendations</h2>
                Using the information you provided in the Research Idea and
                Background Material sections of the Ideation Space, we will
                suggest funding opportunities we think may be relevant to you.
              </div>
              <div>
                <h2 className="font-semibold">Search</h2>A search tool for
                finding calls.
              </div>
              <div>
                <h2 className="font-semibold">Bookmarks </h2>
                Keep track of important calls by bookmarking them. This section
                shows all calls bookmarked by you and any other collaborators on
                the project.
              </div>
            </div>
          }
        />
      </div>
      {calls?.length > 0 ? (
        <ScrollArea className="p-4">
          <div className="flex flex-col gap-y-8">
            {calls.map((call) => (
              <Call {...call} key={call.call_id} projectId={projectId} />
            ))}
          </div>
        </ScrollArea>
      ) : (
        <div className="border-dashed border-4 rounded-md flex flex-col justify-center items-center grow">
          <div className="font-semibold">
            You haven't bookmarked any calls to the project yet.
          </div>
          <div>
            <Link
              className="hover:underline text-fuchsia"
              to={`/project/${projectId}/grant-space/recommended-calls`}
              // onClick={switchToFindCallsTab}
            >
              Search and bookmark calls
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
