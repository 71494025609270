import { ErrorPage, LoadingPage, Title } from "components";
import FindPapersTab from "./find-papers-tab/FindPapersTab";
import { useParams } from "react-router-dom";
import SelectedPapersTab from "./selected-papers-tab/SelectedPapersTab";
import UploadPaperTab from "./upload-papers-tab/UploadPaperTab";
import useSWR from "swr";
import { get_project, get_profile } from "api";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PageHelp } from "@/components/styled/pageHelp";

export default function BackgroundMaterials() {
  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  if (isProjectLoading || isProfileLoading) return <LoadingPage />;
  if (isProjectError || isProfileError) return <ErrorPage />;

  const tabs = [
    {
      label: "Find Papers",
      value: "find-papers",
      content: (
        <>
          <FindPapersTab projectId={projectId} />
        </>
      ),
    },
    {
      label: "Upload Papers",
      value: "upload-papers",
      content: (
        <>
          <UploadPaperTab projectId={projectId} />
        </>
      ),
    },
    {
      label: "Selected Papers",
      value: "selected-papers",
      content: (
        <>
          <SelectedPapersTab projectId={projectId} />
        </>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-y-4 h-full">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Background Material" />
        <PageHelp
          title="Ideation Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Ideation Space is designed to help you refine your research
                idea.
              </div>
              <div>
                <h2 className="font-semibold">Research Idea</h2>
                Here you will tell us a more about what idea you would like to
                get funded.
              </div>
              <div>
                <h2 className="font-semibold">Background Material</h2>
                Enhance our understanding of your idea by searching for and
                linking relevant papers. You can search for papers using Arxiv
                or Semantic Scholar. Note: adding papers here will help us to
                recommend better calls for you in the future.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Ideas</h2>
                Use this section to further develop your idea, take notes, and
                explore new perspectives. You can manually add ideas to the
                brainstorming list, or let us generate suggestions for you.
                Ideas are generated based on your Research Idea, Description,
                and any Background Materials you've provided.
              </div>
              <div>
                <h2 className="font-semibold">Brainstorming Sessions</h2>
                With Sessions, you can easily create brainstorming rooms with
                experts from various backgrounds. Each session lasts a few days,
                and after it ends, we'll provide a summary of the discussion.
                You can start new sessions based on previous topics or begin
                with a fresh idea.
              </div>
            </div>
          }
        />
      </div>

      <Tabs defaultValue="find-papers" className="flex flex-col h-full">
        <TabsList className="w-fit">
          {tabs.map(({ label, value }) => (
            <TabsTrigger value={value} key={value}>
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
        {tabs.map(({ value, content }) => (
          <TabsContent
            value={value}
            key={value + "-content"}
            className="grow data-[state=inactive]:hidden"
          >
            {content}
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
}
