import { ScrollArea } from "@/components/ui/scroll-area";
import { get_project_calls } from "api";
import useSWR from "swr";
import Call from "./Call";
import { Link } from "react-router-dom";
import { ErrorPage, LoadingPage } from "components";

export default function ChooseACall({ projectId }) {
  const {
    data: calls,
    isLoading: isCallsLoading,
    error: isCallsError,
  } = useSWR(`get_project_calls/${projectId}`, () =>
    get_project_calls({ project_id: projectId })
  );

  if (isCallsLoading) return <LoadingPage />;
  if (isCallsError) return <ErrorPage />;

  return (
    <>
      <div className="text-sm">
        It looks like you haven't selected a call yet. Please select a call from
        your bookmarks, manually add a call, or search for a call in our
        database. Your Selected Call represents the funding opportunity you will
        apply to for this project. Only one Selected Call is permitted per
        project. This allows us to provide more tailored information, including
        required documentation and enhanced feedback for Red Team Reviews.
      </div>
      {calls?.length ? (
        <ScrollArea className="px-4">
          <div className="flex flex-col gap-y-8">
            {calls.map((call) => (
              <Call {...call} key={call.call_id} projectId={projectId} />
            ))}
          </div>
        </ScrollArea>
      ) : (
        <div className="border-dashed border-4 rounded-md flex flex-col justify-center items-center grow">
          <div className="font-semibold">
            You haven't bookmarked any calls yet.
          </div>
          <div className="text-base">
            Check out your{" "}
            <Link
              className="text-fuchsia hover:underline"
              to={`/project/${projectId}/grant-space/recommended-calls`}
            >
              recommended calls
            </Link>{" "}
            or{" "}
            <Link
              className="text-fuchsia hover:underline"
              to={`/project/${projectId}/grant-space/search-calls`}
            >
              search for calls
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
