import { delete_brainstorm_idea, update_brainstorm_idea } from "api";
import { MdDelete } from "react-icons/md";

import useSWRMutation from "swr/mutation";
import { mutate } from "swr";
import { LoadingSpinner } from "components";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Trash2Icon } from "lucide-react";

export default function TopicIdea({
  projectId,
  brainstorm_id,
  ai_generated,
  description,
  item,
  index,
  hasEditorAccess,
}) {
  const [tempItem, setTempItem] = useState(item);
  const [tempDescription, setTempDescription] = useState(description);

  const {
    isMutating: isDeleting,
    error: isDeleteError,
    trigger: deleteBrainstormIdea,
  } = useSWRMutation(
    `delete_brainstorm_idea`,
    () =>
      delete_brainstorm_idea({
        project_id: projectId,
        brainstorm_id: brainstorm_id,
      }),
    {
      onSuccess: () => {
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (data) => {
            return {
              ...data,
              items: data.items.filter(
                (d) => d.brainstorm_id !== brainstorm_id
              ),
            };
          },
          { revalidate: false }
        );
      },
    }
  );

  const { trigger: updateBrainstormIdea } = useSWRMutation(
    "update_brainstorm_idea",
    () =>
      update_brainstorm_idea({
        project_id: projectId,
        brainstorm_idea: {
          brainstorm_id: brainstorm_id,
          description: tempDescription,
          item: tempItem,
        },
      }),
    {
      onSuccess: () =>
        mutate(
          `/project/idea/brainstorm/${projectId}`,
          (data) => ({
            ...data,
            items: data.items.map((idea) =>
              idea.brainstorm_id === brainstorm_id
                ? { ...idea, description: tempDescription, item: tempItem }
                : idea
            ),
          }),
          { revalidate: false }
        ),
    }
  );

  return (
    <Dialog>
      <DialogTrigger className="flex flex-col text-xl items-center hover:ring-2 m-2 p-1 gap-y-2 rounded-lg group">
        <div className="flex w-full items-center">
          <span className="whitespace-nowrap">
            {index}. {item}
          </span>
          <span className="bg-fuchsia grow ml-2 h-1 shrink rounded-lg"></span>
        </div>
        <div className="w-full">
          <p className="text-sm text-left">{description}</p>
        </div>
        <div className="w-full items-start">
          <span
            className="text-sm self-start btn-secondary invisible group-hover:visible w-fit hover:opacity-60 hover:underline"
            onClick={(e) => {
              e.stopPropagation();
              deleteBrainstormIdea();
            }}
            disabled={isDeleting}
          >
            Delete Idea{" "}
            {isDeleting ? (
              <LoadingSpinner />
            ) : (
              <Trash2Icon className="inline w-4 h-4" />
            )}
          </span>
        </div>
      </DialogTrigger>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>Brainstorm Idea</DialogTitle>
          <DialogDescription>
            Edit your brainstorm idea below.
          </DialogDescription>
        </DialogHeader>

        <Input
          className=""
          value={tempItem}
          onChange={(e) => setTempItem(e.target.value)}
        />
        <Textarea
          value={tempDescription}
          onChange={(e) => setTempDescription(e.target.value)}
        />
        <div className="flex justify-end">
          <button
            disabled={tempItem === item && tempDescription === description}
            className="btn-fuchsia"
            onClick={updateBrainstormIdea}
          >
            Save
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
