import axiosInstance from "api/axiosInstance";

export const get_chat_uuid = async () => {
  const response = await axiosInstance({
    url: "/chat/uuid",
    method: "POST",

  });
  return response.data;
}

export const send_chat = async ({ uuid, message, agency }) => {
  const response = await axiosInstance({
    url: "/chat/agency_guidelines",
    method: "POST",
    data: {
      uuid: uuid,
      message: message,
      agency: agency
    }
  });

  return {agency: agency, ...response.data};
}