import { Link, Outlet, redirect, useLocation } from "react-router-dom";
import { useAuthStore } from "stores/useAuthStore";
import {
  Sidebar,
  SidebarProvider,
  SidebarTrigger,
} from "@/components/ui/sidebar";
import { AppSidebar } from "./AppSidebar";
import IconSidebar from "./IconSidebar";
import { get_settings } from "api/settings";
import useSWR from "swr";
import { Progress } from "@/components/ui/progress";
import { differenceInDays } from "date-fns";
import { LoadingPage } from "components";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { InfoIcon } from "lucide-react";

export const LayoutLoader = async () => {
  let refreshToken = useAuthStore.getState().refreshToken;
  if (!refreshToken) {
    return redirect("/login");
  }
  return null;
};

/**
 *
 * @param {*} navbar A react component to render the navigation list
 * @returns
 */
export default function Layout() {
  const location = useLocation();
  const { pathname } = location;

  const isProjectRoute = pathname.startsWith("/project");
  const isSettingsRoute = pathname.startsWith("/settings");

  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  if (isGettingSettings) return null;

  return (
    <SidebarProvider defaultOpen={isProjectRoute || isSettingsRoute}>
      <Sidebar
        collapsible="icon"
        className="overflow-hidden [&>[data-sidebar=sidebar]]:flex-row"
      >
        <IconSidebar />
        <AppSidebar />
      </Sidebar>
      <main className="text-black w-full relative">
        {settings?.prompt_upgrade ? (
          <div className="absolute bg-white z-50 top-0 border-b-2 w-full flex justify-between items-center pr-2">
            <SidebarTrigger
              className={"hover:text-fuchsia data-[invisible=true]:invisible"}
              data-invisible={!(isProjectRoute || isSettingsRoute)}
            />

            <div className="flex gap-x-4 items-center justify-end">
              {settings.user_tier === "pro trial" ? (
                <span className="bg-pastel-fuchsia rounded-xl px-2">
                  {`Trial: ${differenceInDays(
                    new Date(settings?.trial_end_date),
                    Date.now()
                  )}
                  Days Left`}
                </span>
              ) : (
                <>
                  <HoverCard openDelay={0}>
                    <HoverCardTrigger className="text-sm whitespace-nowrap flex items-center bg-pastel-fuchsia rounded-lg px-2 gap-x-2">
                      {settings.credits_granted - settings.credits_left} /{" "}
                      {settings.credits_granted} Credits Used
                      <InfoIcon className="inline w-4 h-4" />
                    </HoverCardTrigger>
                    <HoverCardContent className="flex flex-col text-sm max-w-4xl">
                      <div className="font-semibold">
                        Current Credit Cost Per Feature
                      </div>
                      <div className="font-semibold border-b-1 mt-2">
                        1 Credit Per Generation
                      </div>
                      <ul>
                        <li>Brainstorm Ideas</li>
                        <li>QA Agency Guidelines</li>
                        <li>Search For Grants</li>
                      </ul>
                      <div className="font-semibold border-b-1 mt-2">
                        2 Credits Per Generation
                      </div>
                      <ul>
                        <li>Proposal Outline Generation/Regeneration</li>
                        <li>Recommend Grants</li>
                      </ul>
                    </HoverCardContent>
                  </HoverCard>
                </>
              )}
              <div className="flex justify-center">
                <Link
                  to="/settings/billing"
                  className="btn-primary text-base whitespace-nowrap from-fuchsia to-blue bg-gradient-to-br hover:opacity-80 rounded-xl px-3 py-0 h-6 self-center"
                >
                  Upgrade to Pro
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <SidebarTrigger
            className={
              "absolute hover:text-fuchsia data-[invisible=true]:invisible"
            }
            data-invisible={!(isProjectRoute || isSettingsRoute)}
          />
        )}

        <div className="px-8 pt-9 pb-4 h-full max-h-screen">
          <Outlet />
        </div>
      </main>
    </SidebarProvider>
  );
}
