import { ErrorPage, LoadingPage, Title } from "components";
import useSWR from "swr";
import Pricing from "./pricing/Pricing";
import {
  billing_config,
  create_customer,
  get_customer_plan,
} from "api/billing";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState } from "react";
import useSWRMutation from "swr/mutation";
import { useNavigate } from "react-router-dom";
import { remove_timestamp } from "@/lib/utils";
import CurrentPlan from "./current-plan/CurrentPlan";
import { get_settings } from "api/settings";
export default function Billing() {
  const [idempotencyKey] = useState(uuidv4());

  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  const {
    data: customerPlan,
    isLoading: isGettingCustomerPlan,
    error: customerPlanError,
  } = useSWR("get_customer_plan", get_customer_plan);

  const {
    data: billingConfig,
    isLoading: isGettingBillingConfig,
    error: billingConfigError,
  } = useSWR("billing_config", billing_config);

  useEffect(() => {
    if (customerPlan?.create_customer && idempotencyKey) {
      createCustomer();
    }
  }, [customerPlan, idempotencyKey]);

  const { trigger: createCustomer } = useSWRMutation(
    "create_customer",
    () => create_customer({ idempotency_key: idempotencyKey }),
    { onSuccess: (d) => console.log(d) }
  );


  if (isGettingCustomerPlan || isGettingBillingConfig) return <LoadingPage />;
  if (customerPlanError || billingConfigError) return <ErrorPage />;

  const { prices } = billingConfig;
  // TODO: hardcode handling to get monthly plan
  const monthlyPrice = prices.find((p) => p.recurring.interval === "month");

  return (
    <div>
      <Title className="text-4xl" value={"Billing"} />
      <CurrentPlan monthlyPrice={monthlyPrice} customerPlan={customerPlan} prompt_upgrade={settings?.prompt_upgrade} />
      {settings?.prompt_upgrade && (
        <Pricing monthlyPrice={monthlyPrice} customerPlan={customerPlan} />
      )}
    </div>
  );
}
