import { get_action_items, get_project, get_profile } from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import { useParams } from "react-router-dom";
import useSWR from "swr";

import { add_action_item } from "api";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import ActionItem from "./ActionItem";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { PageHelp } from "@/components/styled/pageHelp";
import { useEffect, useState } from "react";

export default function ActionItems() {
  const { projectId } = useParams();

  const [sortedIncompletedActionItems, setSortedIncompletedActionItems] =
    useState([]);
  const [sortedCompletedActionItems, setSortedCompletedActionItems] = useState(
    []
  );

  const {
    data: project,
    isLoading: isProjectLoading,
    error: projectError,
  } = useSWR(
    `get_project/${projectId}`,
    async () => await get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: profileError,
  } = useSWR(`get_profile`, get_profile);

  const {
    data: actionItems,
    isLoading: isActionItemsLoading,
    error: actionItemsError,
  } = useSWR(`get_action_items/${projectId}`, () => {
    return get_action_items({ project_id: projectId });
  });

  const { error: addActionItemError, trigger: addActionItem } = useSWRMutation(
    `add_action_item`,
    () => add_action_item({ project_id: projectId }),
    {
      onSuccess: () => {
        mutate(`get_action_items/${projectId}`);
      },
    }
  );

  useEffect(() => {
    setSortedIncompletedActionItems(
      actionItems
        ?.filter(({ completed }) => !completed)
        .map((item) => ({
          ...item,
          creation_date: new Date(item.creation_time).valueOf(),
        }))
        .toSorted((a, b) => a.creation_date - b.creation_date) || []
    );
    setSortedCompletedActionItems(
      actionItems
        ?.filter(({ completed }) => completed)
        .map((item) => ({
          ...item,
          creation_date: new Date(item.creation_time).valueOf(),
        }))
        .toSorted((a, b) => a.creation_date - b.creation_date) || []
    );
  }, [actionItems]);

  if (isActionItemsLoading || isProjectLoading || isProfileLoading)
    return <LoadingPage />;
  if (actionItemsError || projectError || profileError) return <ErrorPage />;

  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";

  const tabs = [
    {
      label: "All",
      value: "all",
      content: [
        ...sortedIncompletedActionItems,
        ...sortedCompletedActionItems,
      ].map((action_item) => (
        <ActionItem
          {...action_item}
          key={action_item.action_item_id}
          projectId={projectId}
          hasEditorAccess={hasEditorAccess}
        />
      )),
    },
    {
      label: "Todo",
      value: "todo",
      content: [...sortedIncompletedActionItems].map((action_item) => (
        <ActionItem
          {...action_item}
          key={action_item.action_item_id}
          projectId={projectId}
          hasEditorAccess={hasEditorAccess}
        />
      )),
    },
    {
      label: "Completed",
      value: "completed",
      content: [...sortedCompletedActionItems].map((action_item) => (
        <ActionItem
          {...action_item}
          key={action_item.action_item_id}
          projectId={projectId}
          hasEditorAccess={hasEditorAccess}
        />
      )),
    },
  ];

  return (
    <div className="bg-white flex flex-col grow rounded-lg h-full gap-y-4">
      <div className="flex justify-between">
        <Title className="text-4xl" value="Action Items" />
        <PageHelp
          title="Admin Space"
          content={
            <div className="flex flex-col gap-y-4 text-black">
              <div>
                The Admin Space facilitates collaboration and ensures timely
                submission of your grant application.
              </div>
              <div>
                <h2 className="font-semibold">Selected Call</h2>
                View all details about the grant you are applying for. If you
                haven't selected a call yet, you can do so here.
              </div>
              <div>
                <h2 className="font-semibold">Action Items</h2>
                Manage tasks with a to-do list for each project. Create action
                items, assign them to users, set deadlines, and receive email
                reminders for pending tasks.
              </div>
              <div>
                <h2 className="font-semibold">Required Files</h2>
                Track all necessary files and their statuses in one place. Once
                a grant is selected in the Grant Space, we can also recommend
                which files are needed, reducing the time spent on managing
                document requirements.
              </div>
            </div>
          }
        />
      </div>
      <Table>
        <colgroup>
          <col className="w-10"></col>
          <col className=""></col>
          <col className="w-48"></col>
          <col className="w-20"></col>
          <col className="w-[9.5rem]"></col>
        </colgroup>
        <TableCaption className="text-left border-y-[1px]">
          <button
            className="w-full text-left py-2 transition-colors hover:text-black hover:bg-gray-100"
            onClick={addActionItem}
          >
            + Add a new task
          </button>
        </TableCaption>

        <TableHeader>
          <TableRow>
            <TableHead>Complete</TableHead>
            <TableHead>Task</TableHead>
            <TableHead>Assigned</TableHead>
            <TableHead>Priority</TableHead>
            <TableHead className="max-w-2">Due Date</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>{tabs[0].content}</TableBody>
      </Table>
    </div>
  );
}
