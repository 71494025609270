import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { useNavigate } from "react-router-dom";
import { create_followup_session, create_new_brainstorm_session } from "api";
import { mutate } from "swr";

const agent_types = [
  { label: "Sociology", value: "sociology" },
  { label: "Computer Science", value: "computer science" },
  { label: "Data Science", value: "data science" },
  { label: "Economics", value: "economics" },
  { label: "Mechanical Engineering", value: "mechanical engineering" },
  { label: "Chemical Engineering", value: "chemical engineering" },
  { label: "Aerospace Engineering", value: "aerospace engineering" },
  { label: "Statistics", value: "statistics" },
  { label: "Mathematics", value: "mathematics" },
  { label: "Civil Engineering", value: "civil engineering" },
  { label: "Medical", value: "medical" },
  { label: "Education", value: "education" },
  { label: "Computer Biology", value: "biology" },
  { label: "Chemistry", value: "chemistry" },
  { label: "Physics", value: "physics" },
];

export default function CreateNewBrainstormSession({
  projectId,
  finishedSessions,
  user_tier,
}) {
  const navigate = useNavigate();
  const [sessionTitle, setSessionTitle] = useState("");
  const [agent1Background, setAgent1Background] = useState("");
  const [agent2Background, setAgent2Background] = useState("");
  const [followupSessionId, setFollowupSessionId] = useState("new");
  const [isProjectGrounded, setIsProjectGrounded] = useState(false);

  const {
    isMutating: isCreatingBrainstormSession,
    error: createBrainstormSessionError,
    trigger: createBrainstormSession,
  } = useSWRMutation(
    `create_brainstorm_session`,
    () => {
      if (followupSessionId === "new") {
        return create_new_brainstorm_session({
          project_id: projectId,
          name: sessionTitle,
          agent_1_background: agent1Background,
          agent_2_background: agent2Background,
        });
      } else {
        return create_followup_session({
          project_id: projectId,
          session_id: followupSessionId,
          name: sessionTitle,
          agent_1_background: agent1Background,
          agent_2_background: agent2Background,
        });
      }
    },
    {
      onSuccess: (d) => {
        const { session_id } = d;
        navigate(
          `/project/${projectId}/ideation-space/brainstorm-session/${session_id}`
        );
        mutate((key) =>
          key?.startsWith(`get_brainstorm_sessions/${projectId}`)
        );
      },
    }
  );

  if (user_tier === "starter")
    return (
      <Tooltip>
        <TooltipTrigger
          className="btn-fuchsia text-base"
          disabled={user_tier === "starter"}
        >
          Create a new session
        </TooltipTrigger>
        <TooltipContent className="max-w-lg">
          Please upgrade to pro to use this feature.
        </TooltipContent>
      </Tooltip>
    );

  return (
    <Dialog>
      <DialogTrigger className="btn-fuchsia text-base">
        Create a new session
      </DialogTrigger>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle>Create a Session</DialogTitle>
          <DialogDescription>
            {/* Start brainstorming in a chatroom with A.I. agents. */}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-y-4">
          <Input
            placeholder="Session Title"
            value={sessionTitle}
            onChange={(e) => setSessionTitle(e.target.value)}
          />

          <Select value={agent1Background} onValueChange={setAgent1Background}>
            <SelectTrigger>
              <SelectValue placeholder="Researcher 1 Expertise" />
            </SelectTrigger>
            <SelectContent>
              {agent_types.map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select value={agent2Background} onValueChange={setAgent2Background}>
            <SelectTrigger>
              <SelectValue placeholder="Researcher 2 Expertise" />
            </SelectTrigger>
            <SelectContent>
              {agent_types.map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Select
            value={followupSessionId}
            onValueChange={setFollowupSessionId}
          >
            <SelectTrigger>
              <SelectValue placeholder="Start a new session" />
            </SelectTrigger>
            <SelectContent>
              {[
                { label: "Start from scratch", value: "new" },
                ...finishedSessions.map(({ title, session_id }) => ({
                  label: title,
                  value: session_id,
                })),
              ].map(({ label, value }) => (
                <SelectItem key={value} value={value}>
                  {label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <div className="flex items-center gap-x-2">
            <Checkbox
              checked={isProjectGrounded}
              onCheckedChange={(c) => setIsProjectGrounded(c)}
              id="project-grounded"
            />
            <label htmlFor="project-grounded">
              Keep conversation grounded to project
            </label>
          </div>
          <div>
            <button
              onClick={createBrainstormSession}
              disabled={!agent1Background || !agent2Background}
              className="btn-fuchsia text-base"
            >
              Start Session
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
