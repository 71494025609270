import { finalize_google_integration } from "api/integrations";
import { ErrorPage, LoadingPage } from "components";
import { useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";

export default function GoogleDriveConsentResponse() {
  const [searchParams] = useSearchParams();

  const {
    data,
    isMutating: isFinalizingIntegration,
    error: finalizeIntegrationError,
    trigger: finalizeIntegration,
  } = useSWRMutation("finalize_google_integration", (_, { arg }) =>
    finalize_google_integration({ ...arg })
  );

  useEffect(() => {
    if (!searchParams.get("error")) {
      const token = searchParams.get("code");
      const state = searchParams.get("state");
      finalize_google_integration({ token: token, state: state });
    }
  }, []);

  // if (searchParams.get("error"))
  //   return (
  //     <div className="flex flex-col justify-center items-center h-screen text-4xl">
  //       <div>Sorry, there was an issue with integrating with Google.</div>
  //       <div>
  //         <Link to="/settings/integrations" className="link-fuchsia">
  //           Please try again.
  //         </Link>
  //       </div>
  //     </div>
  //   );
  if (isFinalizingIntegration) return <LoadingPage />;
  if (finalizeIntegrationError || searchParams.get("error"))
    return <ErrorPage />;
  return <div>Your account has successfuly integrated with Google Drive.</div>;
}
