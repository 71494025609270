import { get_brainstorm_sessions } from "api";
import { ErrorPage, LoadingPage, Title } from "components";
import { useParams, useSearchParams } from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import useSWR from "swr";

import { PageHelp } from "@/components/styled/pageHelp";
import BrainstormSessionItem from "./BrainstormSessionItem";

import CreateNewBrainstormSession from "./CreateNewBrainSession";
import { get_settings } from "api/settings";

export default function BrainstormSessionList() {
  const { projectId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const num_sessions_per_page = 15;
  const page = Math.max(1, searchParams.get("page"));

  const {
    data: settings,
    isLoading: isGettingSettings,
    error: settingsError,
  } = useSWR("get_settings", get_settings);

  const {
    data: brainstormSessions,
    isLoading: isGettingBrainstormSessions,
    error: brainstormSessionsError,
    isValidating: isValidatingSessions,
  } = useSWR(`get_brainstorm_sessions/${projectId}page=${page}`, () =>
    get_brainstorm_sessions({
      project_id: projectId,
      start_number: (page - 1) * num_sessions_per_page,
      num_session: num_sessions_per_page,
    })
  );

  const {
    data: finishedSessions,
    isLoading: isGettingFinishedSessions,
    error: finishedSessionsError,
    isValidating: isValidatingFinishedSessions,
  } = useSWR(`get_finished_brainstorm_sessions/${projectId}`, () =>
    get_brainstorm_sessions({
      project_id: projectId,
      start_number: 0,
      num_session: 15,
      finished: true,
    })
  );

  if (
    isGettingSettings ||
    isGettingBrainstormSessions ||
    isGettingFinishedSessions ||
    isValidatingSessions ||
    isValidatingFinishedSessions
  )
    return <LoadingPage />;
  if (settingsError || brainstormSessionsError || finishedSessionsError)
    return <ErrorPage />;

  const isLastPage = brainstormSessions?.length < num_sessions_per_page;
  const increasePagination = () => {
    if (!isLastPage) setSearchParams({ page: page + 1 });
  };
  const decreasePagination = () => {
    if (page !== 1) setSearchParams({ page: Math.max(1, page - 1) });
  };

  return (
    <>
      <div className="bg-white flex flex-col grow rounded-lg h-full gap-y-2">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Brainstorm Sessions" />
          <PageHelp
            title="Ideation Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Ideation Space is designed to help you refine your
                  research idea.
                </div>
                <div>
                  <h2 className="font-semibold">Research Idea</h2>
                  Here you will tell us a more about what idea you would like to
                  get funded.
                </div>
                <div>
                  <h2 className="font-semibold">Background Material</h2>
                  Enhance our understanding of your idea by searching for and
                  linking relevant papers. You can search for papers using Arxiv
                  or Semantic Scholar. Note: adding papers here will help us to
                  recommend better calls for you in the future.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Ideas</h2>
                  Use this section to further develop your idea, take notes, and
                  explore new perspectives. You can manually add ideas to the
                  brainstorming list, or let us generate suggestions for you.
                  Ideas are generated based on your Research Idea, Description,
                  and any Background Materials you've provided.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Sessions</h2>
                  With Sessions, you can easily create brainstorming rooms with
                  experts from various backgrounds. Each session lasts a few
                  days, and after it ends, we'll provide a summary of the
                  discussion. You can start new sessions based on previous
                  topics or begin with a fresh idea.
                </div>
              </div>
            }
          />
        </div>
        <div className="text-base">
          Welcome to Sessions — your brainstorming copilot. Easily create rooms
          with experts from various fields, with each session lasting just a few
          days. When it's over, we'll provide a quick recap. Build on past ideas
          or start fresh. Give it a try and see what new ideas you come up with.
        </div>
        <div className="flex justify-end">
          <CreateNewBrainstormSession
            projectId={projectId}
            finishedSessions={finishedSessions}
            user_tier={settings?.user_tier}
          />
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Session Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Created</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {brainstormSessions?.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} className="text-center">
                  There are no sessions.
                </TableCell>
              </TableRow>
            )}
            {brainstormSessions?.map((session) => (
              <BrainstormSessionItem
                key={session.session_id}
                {...session}
                projectId={projectId}
                finishedSessions={finishedSessions}
              />
            ))}
          </TableBody>
        </Table>

        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className="aria-disabled:text-gray-500 aria-disabled:hover:bg-inherit cursor-pointer aria-disabled:cursor-default"
                aria-disabled={page === 1}
                onClick={decreasePagination}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink className="cursor-default hover:bg-inherit">
                {page}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationNext
                className="aria-disabled:text-gray-500 aria-disabled:hover:bg-inherit cursor-pointer aria-disabled:cursor-default"
                aria-disabled={isLastPage}
                onClick={increasePagination}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </>
  );
}
